import App from './App.vue'
import i18nMsg from './i18n/index'
import vRouter from './router'
import './router/beforeEach'
import store from './store'
import vueWaves from '@/utils/waves'
import config from './api/config'
import { cacheUrlParam, isGreenChannel } from '@/utils/common'
import common from '@/components/index'
import clipboard from 'clipboard'
import '@/assets/css/main.scss'
import '@edge/publicis-common/packages/assets/fonts/fonts.css'
import '@/assets/iconfont/iconfont.css'
import VueTypedJs from 'vue-typed-js'

// import 'font-awesome/css/font-awesome.min.css';
const { OneUI, Vue, i18n, $sessionStorage, $localStorage } = $PCommon
const { VUE_APP_GAID } = process.env
const Gtag = require('vue-gtag')

i18n.mergeLocaleMessage('zh-cn', i18nMsg['zh-cn'])
i18n.mergeLocaleMessage('en-us', i18nMsg['en-us'])
Vue.use(OneUI)
Vue.use(vueWaves)
Vue.use(common)
Vue.use(VueTypedJs)
// 加载谷歌监测组件
Vue.use(
  Gtag,
  {
    config: {
      id: VUE_APP_GAID,
      send_page_view: false,
    },
  },
  vRouter,
)
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css' //样式

//创建v-highlight全局指令
Vue.directive('highlight', function (el) {
  let blocks = el.querySelectorAll('pre code')
  blocks.forEach((block) => {
    hljs.highlightBlock(block)
  })
})
// makdown复制插件
Vue.prototype.clipboard = clipboard
// response状态码
Vue.prototype.$RESPONSE_STATUS = config.RESPONSE_STATUS
Vue.prototype.$PROJECT_CODE = config.PROJECT_CODE

// 初始化
const initApp = () => {
  const userInfo = $sessionStorage.getItem('userInfo')
  if (userInfo) {
    // 将lionID提供给gtag监测数据
    Vue.$gtag &&
      Vue.$gtag.config({
        send_page_view: false,
        lionId: userInfo.lionId || userInfo.username,
      })
  }

  new Vue({
    router: vRouter,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app')
}

// 获取SSO重定向在url中携带的token，有则存储到sessionStorage便于后续接口使用
cacheUrlParam('token', config.USER_TOKEN_KEY)

// 绿色通道路由直接挂载Vue
if (isGreenChannel({ children: vRouter.options.routes })) {
  // 绿色通道路由直接挂载Vue
  initApp()
} else {
  try {
    const mergeApi = []
    // 获取用户权限列表，并缓存
    mergeApi.push($Api.user.queryAccessAll())
    // 获取用户登录信息，并缓存
    if (!$sessionStorage.getItem('userInfo')) {
      mergeApi.push($Api.user.getUserInfo())
    }
    Promise.all(mergeApi).then((res) => {
      const [accessRes, userRes] = res
      if (accessRes.status === config.RESPONSE_STATUS.SUCCESS) {
        $sessionStorage.setItem(config.ACCESS_GROUP, accessRes.data)
      }
      if (userRes && userRes.status === config.RESPONSE_STATUS.SUCCESS) {
        $sessionStorage.setItem('userInfo', userRes.data)
      }
      // 挂载Vue
      initApp()
    })
  } catch (err) {
    throw new Error(err)
  }
}
