const { VUE_APP_BASEURL } = process.env
export default {
  /**
   * 公告列表
   */
  announcementList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/announcement/list`, { params: data })
  },
  /**
   * 新增公告
   */
  addAnnouncement(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/announcement/add`, params)
  },
  /**
   * 删除公告
   */
  delAnnouncement(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/announcement/del`, params)
  },
  /**
   * 编辑公告
   */
  updAnnouncement(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/announcement/upd`, params)
  },
  /**
   * 上下架公告
   */
  updStatusAnnouncement(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/announcement/upd-status`, params)
  },
  /**
   * 公告详情
   */
  detailsAnnouncement(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/announcement/details`, {
      params: data,
    })
  },
  /**
   * 公告弹窗
   */
  popupAnnouncement(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/announcement/pop-up`, {
      params: data,
    })
  },
  /**
   * 弹窗历史公告
   */
  historyAnnouncement(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/announcement/history`, {
      params: data,
    })
  },
  /**
   * 上传图标
   */
  upAnnouncementImg(params) {
    const formData = new FormData()
    if (params)
      Object.keys(params).map((key) => {
        formData.append(key, params[key])
      })
    return $Request.post(
      `${VUE_APP_BASEURL}/api/security/admin/announcement/file-upload`,
      formData,
      {
        upload: true,
        timeout: 5 * 60 * 1000,
      },
    )
  },
}
