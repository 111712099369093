import router from './index'
import store from '@/store'
import homeConfig from './homeConfig'

/**
 * 路由节点权限过滤
 * 递归
 */
const FILTER_CONFIG_FN = (hConfig, accessCode, accessCodeList) => {
  let findConfig = hConfig.find((x) =>
    x.meta ? (x.meta.accessCode ? accessCode === x.meta.accessCode : false) : false,
  )
  if (findConfig && findConfig.meta && findConfig.meta.accessCode) {
    accessCodeList.push(findConfig.meta.accessCode)
    return findConfig
  }
  hConfig.forEach((x) => {
    if (x.children && x.children.length) {
      var xFindConfig = FILTER_CONFIG_FN(x.children, accessCode, accessCodeList)
    }
    if (xFindConfig && xFindConfig.meta && xFindConfig.meta.accessCode) {
      if (x.meta && !x.meta.accessCode == '') accessCodeList.push(x.meta.accessCode)
      findConfig = x
    }
  })
  return findConfig
}

router.beforeEach(async (to, from, next) => {
  // 动态标题
  if (to.meta.title) {
    if (!!to.meta.path && to.meta.path == 'home') {
      document.title = to.meta.title
    } else document.title = `${process.env.VUE_APP_BASENAME} - ${to.meta.title}`
    // 谷歌监测，手动触发PV监测
    $PCommon.Vue.$gtag &&
      $PCommon.Vue.$gtag.pageview({
        page_title: document.title,
        page_path: to.fullPath,
      })
  }

  // 路由权限鉴定
  // 小问题：当前路由为子级菜单，当父级权限没有时，子级菜单权限有，会在菜单组件中跳转有权限的路由（应该跳转404），暂不用修复，
  // 如需修复需要连父级code一同校验
  if (to.meta && to.meta.accessCode) {
    // 获取当前路由及上级路由所有accessCode列表
    const accessCodeList = []
    FILTER_CONFIG_FN(homeConfig, to.meta.accessCode, accessCodeList)
    // 判断accessCodeList是否包含不存在于用户权限列表的accessCode
    if (
      Array.from(new Set(accessCodeList.concat(store.getters.accessList))).length >
      store.getters.accessList.length
    ) {
      next('/401')
      return
    }
  }
  next()
})
