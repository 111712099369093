<template>
  <div>
    <el-dialog
      :append-to-body="true"
      :top="topContent || '5%'"
      :visible.sync="show"
      :title="titleMsg"
      :close-on-click-modal="false"
      :width="dialogWith"
      v-bind="$attrs"
      v-on="$listeners"
      class="ai-submit-dialog"
      @close="closed"
    >
      <div :style="{ fontSize: fontN + 'px' }"><slot name="body"></slot></div>
      <div slot="footer">
        <slot name="footer">
          <el-button v-if="showOneBtn" class="marginR20" @click="closeDialog">{{
            btnLeft
          }}</el-button>
          <el-button type="primary" @click="submit">{{ btnRight }}</el-button>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'SSOSubmitDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dialogWith: [String, Number, null],
    topContent: [String, Number, null],
    fontN: {
      type: String,
      default: '12px',
    },
    showOneBtn: {
      type: Boolean,
      default: true,
    },
    btnLeftContent: [String, Number, null],
    btnRightContent: [String, Number, null],
    title: [String, Number, null],
    content: [String, Number, null],
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
    title(v) {
      this.titleMsg = v
    },
    btnLeftContent(v) {
      this.btnLeft = v
    },
    btnRightContent(v) {
      this.btnRight = v
    },
  },
  data() {
    return {
      show: this.visible,
      dWith: this.dialogWith,
      titleMsg: this.title || this.$t('dc:提醒'),
      btnLeft: this.btnLeftContent || this.$t('dc:取消'),
      btnRight: this.btnRightContent || this.$t('dc:确认'),
    }
  },
  methods: {
    submit() {
      this.$emit('submit')
    },
    closed() {
      this.$emit('close')
    },
    closeDialog() {
      this.show = false
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss">
.ai-submit-dialog .el-dialog .el-dialog__body {
  padding: 0 16px;
}
.ai-submit-dialog .el-dialog__title {
  font-size: 16px;
  font-weight: 600;
}
.ai-submit-dialog .el-dialog .el-dialog__footer {
  padding: 16px;
}
</style>
