<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="80%"
      top="5%"
      :class="homeClass"
      :append-to-body="true"
      :close-on-click-modal="false"
      class="launch-announcement-dialog"
      :title="$t('dc:公告')"
      @close="closed"
    >
      <div class="launchContent fontB6">
        <ul>
          <li>
            <div>
              <div>{{ launchDataList.launchTime }}</div>
              <div>{{ launchDataList.title }}</div>
            </div>
            <div>
              <div
                class="markdown-body"
                v-highlight
                v-html="$options.filters.changeData(launchDataList.content)"
              ></div>
              <!-- <span>
                {{ launchDataList.content }}
              </span> -->
            </div>
          </li>
        </ul>
      </div>
      <div slot="footer">
        <!-- <el-button type="primary" @click="goNextPage">{{$t('dc:下一页')}}</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import config from '@/api/config'
import { $http } from '../../api/http'
import marked from 'marked'
export default {
  name: 'launchDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    homeClass: {
      type: String,
      default: '',
    },
    launchDataList: {},
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  filters: {
    changeData: (val) => {
      return marked.parse(val)
    },
  },
  data() {
    return {
      show: this.visible,
      token: this.$sessionStorage.getItem(config.USER_TOKEN_KEY),
      otherClass: 'home',
      // showPage: this.showNextPage
    }
  },
  created() {},
  mounted() {},

  methods: {
    goNextPage() {},
    closed() {
      this.$emit('close')
      this.show = false
    },
  },
}
</script>
<style lang="scss">
.launchTile {
  display: block;
  color: #000;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
}

.launchContent {
  // width: 400px;
  // overflow: hidden;
  ul {
    min-height: 500px;
    background: rgba(244, 244, 244, 1);
    max-height: 320px;
    height: 320px;
    padding: 8px;
    // width: 415px;
    overflow-y: scroll;
    // overflow-x: hidden;
    display: block;
    border: rgba(207, 210, 216, 1);
  }
  li {
    width: 100%;
    text-align: left;
    margin-bottom: 12px;
    border-radius: 8px;
    div {
      font-weight: 500;
    }
    span {
      white-space: pre-line;
      line-height: 1.3;
      font-weight: 300;
      word-wrap: break-word;
      word-break: keep-all;
    }
  }
  ul::-webkit-scrollbar {
    /*滚动条整体样式*/

    width: 4px; /*高宽分别对应横竖滚动条的尺寸*/

    height: 1px;
  }

  ul::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/

    border-radius: 4px;

    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);

    background: #535353;
  }

  ul::-webkit-scrollbar-track {
    /*滚动条里面轨道*/

    // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1);

    border-radius: 4px;

    background: transparent;
  }
  // li:hover{
  //     cursor: pointer;
  //     background: rgba(89, 226, 0, 1);
  //     div{
  //         color: #000;
  //         font-weight: 600;
  //     }
  // }
}
</style>
