// import PublicisCommon from '@edge/publicis-common';
import config from '@/api/config'
const { $sessionStorage, $localStorage } = $PCommon

const getters = {
  // 当前行业
  userInfo(state) {
    let userInfo = state.userInfo
    if (!userInfo) {
      userInfo = $sessionStorage.getItem('userInfo')
    }
    return userInfo
  },
  isCollapse(state) {
    return state.isCollapse
  },
  managerStatus(state) {
    return state.managerStatus
  },
  // app版本列表
  appLinkList(state) {
    return state.appLinkList
  },
  // 权限列表
  accessList(state) {
    let accessList = []
    let accessGroup = state.accessGroup.length
      ? state.accessGroup
      : $sessionStorage.getItem(config.ACCESS_GROUP)
    accessGroup.forEach((x) => {
      accessList = accessList.concat(x.accessList)
    })
    return Array.from(new Set(accessList))
  },
  // 记录
  lang(state) {
    return state.lang
  },
  //代理商列表
  agencyList(state) {
    return state.agencyList
  },
  //客户列表
  clientList(state) {
    return state.clientList
  },
}

export default getters
