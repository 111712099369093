const { VUE_APP_BASEURL } = process.env
export default {
  /**
   *  获取品牌列表
   */
  getAllAgencyList() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/common/option/agency`)
  },
  /**
   *  获取代理列表
   */
  getAllClientList() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/common/option/client`)
  },
  /**
   * 获取组列表
   */
  getAllGroupList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/common/option/group-list`, {
      params: data,
    })
  },
}
