<template>
  <el-drawer
    v-loading="loading"
    custom-class="product"
    id="productDrawer"
    :wrapperClosable="false"
    :modal="false"
    :visible.sync="show"
    :direction="direction"
    @opened="openDrawer"
    :before-close="handleClose"
    @closed="closeDrawer"
  >
    <div slot="title">
      <slot name="header"></slot>
    </div>
    <div class="publicis-data-center" v-if="showDarwer">
      <div class="productDetail">
        <div class="productDetail-header">
          <div class="productDetail-agency">
            <div class="productDetail-agency-box">
              <div class="productDetail-agency-box-left">
                <img :src="allData.iconimgUrl" alt="" />
                <div>
                  <h4>{{ allData.name }}</h4>
                  <div>
                    <i class="el-icon-user-solid"></i>
                    <span>{{ allData.adminName }}</span>
                  </div>
                </div>
              </div>
              <div class="productDetail-agency-box-right">
                <el-tooltip
                  :content="
                    allData.hasAccess && allData.link !== ''
                      ? 'Go To ' + allData.name
                      : $t('dc:获取访问权限')
                  "
                  placement="top"
                >
                  <el-button
                    style="width: 160px"
                    type="info"
                    class="goProjectBtn"
                    :class="bgActive"
                    @click="goProject"
                    >{{
                      allData.hasAccess && allData.link !== ''
                        ? 'Go To ' + allData.name
                        : $t('dc:获取访问权限')
                    }}</el-button
                  >
                </el-tooltip>
              </div>
            </div>
          </div>
          <!-- <div class="productDetail-tabbox">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(item, index) in tabData" :key="index" :label="item.title" :name="item.name"
                  :disabled=item.disabled></el-tab-pane>
              </el-tabs>
            </div> -->
        </div>
        <div ref="contentBox" id="contentBox" class="productDetail-main">
          <div class="content" v-if="showData">
            <div v-for="(item, index) in tabData" :key="index" :id="item.name">
              <div>
                <h5 v-if="!item.disabled">{{ item.title }} <i class="borderGreen"></i></h5>
                <p v-if="(index == 0 || index == 1) && !item.disabled" v-html="item.explain"></p>
                <div
                  v-if="
                    index == 2 &&
                    !item.disabled &&
                    (fbDataLeft.list.length > 0 || fbDataRight.length > 0)
                  "
                >
                  <el-row :gutter="20">
                    <el-col :span="10">
                      <div class="FB-box textR">
                        <h4>{{ fbDataLeft.title }}</h4>
                        <ul>
                          <li v-for="(item, index) in fbDataLeft.list">
                            <div class="flx JCFE">
                              <div class="FB-box-Left">
                                <h5>{{ item.title }}</h5>
                                <span>{{ item.description }} </span>
                              </div>
                              <div class="num">
                                {{ index + 1 }}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <img class="FB-img" :src="allData.iconimgUrl" alt="" />
                      <span class="FB-name">{{ allData.name }}</span>
                    </el-col>
                    <el-col :span="10">
                      <div class="FB-box textL">
                        <h4>{{ fbDataRight.title }}</h4>
                        <ul>
                          <li v-for="(item, index) in fbDataRight.list">
                            <div class="flx">
                              <div class="num">
                                {{ index + 1 }}
                              </div>
                              <div class="FB-box-Right">
                                <h5>{{ item.title }}</h5>
                                <span>{{ item.description }} </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div
                  class="publicis-data-center-body productDetail-carouse"
                  v-if="index == 3 && !item.disabled"
                >
                  <carouselTmp
                    v-if="item.dataList.length > 0"
                    :appList="item.dataList"
                  ></carouselTmp>
                </div>
                <div v-if="index == 4 && !item.disabled">
                  <ul
                    class="productDetail-main-filesbox"
                    :class="{ paddingB40: item.dataList.length < 4 }"
                  >
                    <li v-for="(item, index) in item.dataList" :key="index">
                      <span>{{ item.description }}</span>
                      <el-button class="btn-green" type="primary" @click="downloadFiles(item)">{{
                        $t('dc:下载')
                      }}</el-button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="noDataImg" v-else>
            <img :src="noData" alt="" />
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import config from '@/api/config'
import { mapGetters } from 'vuex'
import getAllData from '@/utils/getApplist'
import carouselTmp from '@/views/components/carouselTmp.vue'
import noData from '@/assets/img/noData.png'
export default {
  name: 'productDrawerDialog',
  components: {
    carouselTmp,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    appIndexId: {
      type: [String, Number],
      default: 0,
    },
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
    loading(v) {},
    lang: {
      immediate: false,
      deep: false,
      handler(to) {
        if (this.appIndexId == '') return
        this.loading = true
        this.getProjectDetailData(this.appIndexId)
      },
    },
  },
  data() {
    return {
      noData,
      direction: 'btt',
      show: this.visible,
      showDarwer: false,
      loading: false,
      groupManagerId: '',
      groupName: '',
      allData: {},
      activeName: 'IT',
      goLinkBtn: this.$t('dc:转到主页'),
      bgActive: 'btn-green',
      iconImgBox: [],
      showData: true,
      showFold: false,
      scrollTab: {
        one: {
          scrollHeight: '',
        },
        two: {
          scrollHeight: '',
        },
        three: {
          scrollHeight: '',
        },
        four: {
          scrollHeight: '',
        },
        five: {
          scrollHeight: '',
        },
      },
      contentBoxCliheight: '',
      fbDataLeft: {
        title: 'CORE FEATURES',
        list: [],
      },
      fbDataRight: {
        title: 'KEY BENEFITS',
        list: [],
      },
    }
  },
  computed: {
    ...mapGetters(['lang']),
    tabData() {
      return [
        {
          title: this.$t('dc:produc描述'),
          name: 'IT',
          disabled: true,
          explain: '',
        },
        {
          title: this.$t('dc:用途'),
          name: 'Usage',
          disabled: true,
          explain: '',
        },
        {
          title: this.$t('dc:特点和优势'),
          name: 'FeaturesBenefits',
          disabled: true,
          dataList: [],
        },
        // {
        //   title: this.$t('dc:如何访问?'),
        //   name: 'GETACCESS',
        //   disabled: true,
        //   explain: ''
        // },
        {
          title: this.$t('dc:演示'),
          name: 'DEMO',
          disabled: true,
          dataList: [],
        },
        {
          title: this.$t('dc:文件夹'),
          name: 'FILES',
          disabled: true,
          dataList: [],
        },
      ]
    },
  },
  created() {
    this.iconImgBox = getAllData()
  },

  mounted() {
    this.initEvent()
  },
  methods: {
    initData() {
      if (document.getElementById('IT') !== null)
        this.scrollTab.one.scrollHeight = document.getElementById('IT').offsetTop - 32
      if (document.getElementById('FeaturesBenefits') !== null)
        this.scrollTab.two.scrollHeight = document.getElementById('FeaturesBenefits').offsetTop - 32
      if (document.getElementById('GETACCESS') !== null)
        this.scrollTab.three.scrollHeight = document.getElementById('GETACCESS').offsetTop - 32
      if (document.getElementById('DEMO') !== null)
        this.scrollTab.three.scrollHeight = document.getElementById('DEMO').offsetTop - 32
      if (document.getElementById('FILES') !== null)
        this.scrollTab.three.scrollHeight = document.getElementById('FILES').offsetTop - 32
    },
    handleClose() {
      this.show = false
    },
    shwoGroupManager() {
      this.$emit('showGroupManager', true)
    },
    openDrawer() {
      this.loading = true
      this.getProjectDetailData(this.appIndexId)
    },
    initEvent() {
      if (this.appIndexId == '') return
    },
    async getProjectDetailData(parmans) {
      this.allData = {}
      this.fbDataRight.list = []
      this.fbDataLeft.list = []
      const { status, data } = await $Api.project.getListInfo(parmans)
      if (status == config.RESPONSE_STATUS.SUCCESS) {
        this.showDarwer = true
        this.allData = data
        this.iconImgBox.map((iconItem) => {
          if (iconItem.id === data.id) {
            this.allData.iconimgUrl = iconItem.iconimgUrl
          }
        })

        this.tabData[0].explain = data.attribute1
        this.tabData[1].explain = data.attribute2
        // this.tabData[2].explain = data.attribute3
        // this.tabData[2].explain = data.attribute4
        // this.tabData[4].explain = data.attribute1
        // this.tabData[3].dataList = data.demo
        // this.tabData[4].dataList = data.files
        if (this.tabData[0].explain == '' || this.tabData[0].explain == null)
          this.tabData[0].disabled = true
        else this.tabData[0].disabled = false
        if (this.tabData[1].explain == '' || this.tabData[1].explain == null)
          this.tabData[1].disabled = true
        else this.tabData[1].disabled = false
        if (data.featuresBenefits.length > 0) {
          data.featuresBenefits.map((item, index) => {
            if (index < 3) this.fbDataLeft.list.push(item)
            else this.fbDataRight.list.push(item)
          })
        }
        // this.tabData.map((item,index) => {
        //   if (item.explain && (item.explain !== '' || item.explain !== null)) item.disabled = false
        //   if (item.dataList && item.dataList.length !== 0) item.disabled = false
        // })
        // 第三部分
        if (this.fbDataLeft.list.length == 0 && this.fbDataRight.list.length == 0)
          this.tabData[2].disabled = true
        else this.tabData[2].disabled = false
        //  判断展示NoData
        for (let index = 0; index < this.tabData.length; index++) {
          if (!this.tabData[index].disabled) {
            this.showData = true
            break
          } else this.showData = false
        }

        if (this.allData.hasAccess && this.allData.link !== '') {
          this.bgActive = 'btn-green'
        } else {
          this.bgActive = 'btn-blue'
        }

        setTimeout(() => {
          this.loading = false
        }, 100)
      }
    },
    showOut(e) {
      this.initData()
      if (e.srcElement.scrollTop < this.scrollTab.two.scrollHeight || e.srcElement.scrollTop == 0)
        this.activeName = 'IT'
      else if (
        this.scrollTab.two.scrollHeight < e.srcElement.scrollTop &&
        e.srcElement.scrollTop < this.scrollTab.three.scrollHeight
      ) {
        if (this.scrollTab.two.scrollHeight == '') return
        this.activeName = 'FeaturesBenefits'
      } else if (
        this.scrollTab.three.scrollHeight < e.srcElement.scrollTop &&
        e.srcElement.scrollTop < this.scrollTab.four.scrollHeight
      ) {
        if (this.scrollTab.three.scrollHeight == '') return
        this.activeName = 'GETACCESS'
      } else if (
        this.scrollTab.four.scrollHeight < e.srcElement.scrollTop &&
        e.srcElement.scrollTop < this.scrollTab.five.scrollHeight
      ) {
        if (this.scrollTab.four.scrollHeight == '') return
        this.activeName = 'DEMO'
      } else {
        if (this.scrollTab.five.scrollHeight == '') return
        this.activeName = 'FILES'
      }
    },
    showAllFold() {
      this.showFold = false
      // this.contentBoxCliheight = '100%'
    },
    goBack() {
      this.$router.back()
    },
    async clickNum() {
      let { status } = await $Api.user.loginNum({ productId: this.allData.id })
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.goProject()
      }
    },
    async goProject() {
      let token = this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      if (this.allData.link !== '') {
        let { status } = await $Api.user.loginNum({ productId: this.allData.id })
        if (status === this.$RESPONSE_STATUS.SUCCESS) {
          window.open(`${this.allData.link}?token=${token}`, '_blank')
        }
      } else {
        this.$emit('showProductGroupManager', this.appIndexId)
      }
    },
    handleClick(tab, event) {
      this.$refs.contentBox.scrollTop = document.getElementById(tab.name).offsetTop - 32
    },
    downloadFiles(item) {
      window.location.href = item.url
    },
    closeDrawer() {
      this.showDarwer = false
      // this.activeName = "IT"
      // this.$refs.contentBox.scrollTop= 0;
      // this.scrollTab.one.scrollHeight = ''
      // this.scrollTab.two.scrollHeight = ''
      // this.scrollTab.three.scrollHeight = ''
      // this.scrollTab.four.scrollHeight = ''
      // this.scrollTab.five.scrollHeight = ''
      this.allData = {}
    },
  },
}
</script>
<style lang="scss" lengend>
// @media screen and  (max-width: 1367px){
//   @import "@/assets/css/productDetailSmail.scss";
// }
// @media screen and (min-width:1367px) and (max-width: 1440px){
//   @import "@/assets/css/portalSmallMin.scss";
// }
// @media screen and (min-width:1441px) and (max-width: 1553px){
//   @import "@/assets/css/productDetailMin.scss";
// }
// @media screen and (min-width:1554px) and (max-width:1920px){
//   @import "@/assets/css/productDetailPuls.scss";
// }
// @media screen and (min-width:1921px) and (max-width:2040px){
//   @import "@/assets/css/portalBigPuls.scss";
// }

.noAllowed {
  cursor: not-allowed;
}
.el-button.btn-grey {
  background: #ccc;
  cursor: not-allowed;
}
.el-button.btn-blue {
  background: linear-gradient(to bottom, #b6ebff, #66d6ff) !important;
}

.el-button.btn-green.goProjectBtn {
  background: linear-gradient(to bottom, #81f31e, #4cc800);
  text-align: center;
}
// .goProjectBtn span {
//   width: 160px;
// }
@media screen and (max-width: 1553px) {
  @import '@/assets/css/productDetailMin.scss';
}

@media screen and (min-width: 1553px) {
  @import '@/assets/css/productDetailPuls.scss';
}
</style>
